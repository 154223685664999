<template>
  <div :style="marginTop">
    <div class="page-title">方片透视数据导出</div>
    <div style="width: 100%;padding:1rem 0 0.3rem 0;">
      <el-select
        v-model="来源厂家"
        clearable
        multiple
        allow-create
        default-first-option
        filterable
        placeholder="-来源厂家-"
        style="width:200px"
      >
        <el-option
          v-for="item in 来源厂家组"
          :value="item"
          :label="item"></el-option>
      </el-select>
    </div>
    <div style="float:left;width:100%;margin-top:1rem">
      <strong>按大分类导出</strong>
    </div>
    <div style="float:left;margin-top:1rem">
      <el-button
        type="primary"
        plain
        size="mini"
        v-for="item in 按分类"
        style="margin:5px"
        @click="SquarePvOut(item)">{{ item }}
      </el-button>
    </div>
    <div style="float:left;width:100%;margin-top:1rem">
      <strong>按具体型号导出</strong>
    </div>
    <div style="float:left;margin-top:1rem">
      <el-button
        type="primary"
        plain
        size="mini"
        v-for="item in 按型号"
        style="margin:5px"
        @click="SquarePvOut(item.型号)">{{ item.型号 }}
      </el-button>
    </div>
    <div style="float:left;width:100%;margin-top:1rem">
      <strong>导出全部</strong>
    </div>
    <div style="float:left;margin-top:1rem">
      <el-button
        type="primary"
        plain
        size="mini"
        style="margin:5px"
        @click="SquarePvOut()">导出全部透视数据
      </el-button>
      <el-button
        type="primary"
        plain
        size="mini"
        style="margin:5px"
        @click="SquarePvSum">仅导出数量总览
      </el-button>
    </div>
  </div>
</template>

<script>
import {baseTitle, baseUrl, squarePvRanges} from "../configs/squareConfig";
import {formRequest} from "../http/formRequest";

export default {
  name: "ProdSquarePvOut",
  data() {
    return {
      marginTop: '',
      按分类: ['紫光', '蓝光', '绿光', '黄绿光', 'RGB'],
      按型号: squarePvRanges,
      来源厂家组: ['<排除>', '<空白>', '圆融', '兆元', '兆元20A'],
      来源厂家: [],
    }
  },
  methods: {
    SquarePvOut(props) {
      formRequest(baseUrl + '/square-pv-output', {
        token: this.$cookies.get('token'),
        props,
        来源厂家: JSON.stringify(this.来源厂家),
      })
    },
    SquarePvSum() {
      formRequest(baseUrl + '/square-sku-sum', {
        token: this.$cookies.get('token'),
        来源厂家: JSON.stringify(this.来源厂家),
      })
    },
  },
  activated() {
    document.title = '方片透视数据导出 - ' + baseTitle
    const obj = document.querySelector('.el-menu-demo')
    setTimeout(() => this.marginTop = `margin-top:${obj.clientHeight - 50}px`, 100)
  }
}
</script>

<style scoped>

</style>
